<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h1 class="brand-text text-primary ml-1">
            Security Assessment
          </h1>
        </b-link>
        <b-card-text>
          <process phase="Assessment" />
        </b-card-text>
        <b-card-text class="text-center mb-2">
          <h2>Thank you, we will email you the security assessment results.</h2>
        </b-card-text>
        <b-card-text class="text-center">
          <b-link traget="_blank" href="https://calendly.com/saasment-experts/30min?month=2021-07" class="btn btn-outline-primary mr-1">Schedule Meeting</b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>

import {
  BCard, BLink, BCardText,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Process from './Process.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    Process,
  },
  data() {
    return {
      gw_auth_link: '',
    }
  },
  mounted() {
    useJwt.addLogin2({
      type: 'sso',
      user: 'root',
      app_name: 'Google Workspace',
      details: {
        code: this.$route.query,
      },
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
